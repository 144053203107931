function Contact() {
  return (
    <section id="contact" className=" bg-slate-950 text-white p-5">
      <h2 className="text-3xl text-purple-500">Contactez-moi</h2>
      <a href="#">ilann.barreteau.pro@gmail.com</a>
      <a href="#">+33 6 82 83 84 56</a>
      <form className="flex flex-col items-center" action="">
        <input className="bg-slate-700 my-2 h-8 pl-2 border rounded-md" type="text" placeholder="Votre nom" />
        <input className="bg-slate-700 my-2 h-8 pl-2 border rounded-md" type="text" placeholder="Votre prenom" />
        <input className="bg-slate-700 my-2 h-8 pl-2 border rounded-md" type="email" placeholder="Votre mail" />
        <input className="bg-slate-700 my-2 h-8 pl-2 border rounded-md" type="text" placeholder="Votre message..." />
        <input className="bg-slate-700 my-2 h-8 pl-2 border rounded-md" type="submit" value="" />
      </form>
    </section>
  );
}

export default Contact;
