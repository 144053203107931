function Experience() {
  function Experiences() {
    return (
      <li> </li>
    );
  }


  return (
    <section id="experience" className=" flex flex-col justify-start items-center h-screen bg-slate-950 text-white">
      <h2 className="text-purple-500 text-3xl mb-5">Experiences</h2>
      <ul className="flex flex-row justify-around  w-full">
        <ul className="flex items-center flex-col bg-slate-900 border rounded-3xl m-2 text-center font-semibold row-span-2 w-full h-full">
          <h3 className="w-28 text-center ">Expériences Professionnelles</h3>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul className="flex items-center flex-col bg-slate-900 border rounded-3xl m-2 text-center font-semibold row-span-2 w-full h-full">
          <h3 className="w-28 text-center">Formation</h3>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </ul>
    </section>
  );
}

export default Experience;
