import home from "../assets/img/ico/svg/home-outline.svg";
import profil from "../assets/img/ico/svg/user.svg";
import skill from "../assets/img/ico/svg/keyboard.svg";
import portfolio from "../assets/img/ico/svg/paste.svg";
import exp from "../assets/img/ico/svg/home-outline.svg";
import contact from "../assets/img/ico/svg/adresse-card.svg";
// import { byPrefixAndName } from "@awesome.me/kit-KIT_CODE/icons";

// import humburger from "../assets/img/ico/svg/humburger.png";
import Contact from "./Contact.jsx";
import Home from "./01-Home.jsx";
import Profil from "./02-Profil.jsx";
import Portfolio from "./05-Portfolio.jsx";
import Skills from "./03-Skills.jsx";
import Experience from "./04-Experience.jsx";
import Footer from "./06-Footer.jsx";

function Nav() {
  function Btn({ link, srcImg, imgClass }) {
    return (
      <a href={link}>
        <img src={srcImg} className={imgClass + "h-5 w-10 px-2"} />
      </a>
    );
  }

  return (
    <section
      id="nav"
      className="flex flex-row justify-center items-center fixed bottom-5 inset-x-14 min-w-10 min-h-10 bg-slate-800 border rounded-3xl"
    >
      <Btn link="#home" srcImg={home} />
      <Btn link="#profil" srcImg={profil} />
      <Btn link="#skill" srcImg={skill} />
      <Btn link="#experience" srcImg={exp} />
      <Btn link="#portfolio" srcImg={portfolio} />
      <Btn link="#contact" srcImg={contact} />
    </section>
  );
}

export default Nav;
