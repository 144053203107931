function Profil() {
  function TextProfil({ profilClass, value }) {
    return <p className={profilClass + " text-center mx-5"}>{value}</p>;
  }

  return (
    <section id="profil" className="h-screen bg-slate-950 text-white">
      <h2 className="text-4xl font-semibold text-purple-500 ml-5 py-5">
        A propos de moi
      </h2>
      <TextProfil
        value={
          "Je suis un développeur web passionné. Grâce à mes stages en programmation d'applications et en développement web, j'ai pu affiner mes compétences dans divers langages et frameworks."
        }
      />
      <TextProfil
        value={
          "Mon objectif est de créer des solutions web élégantes et centrées sur l'utilisateur. Ma passion pour le développement web me pousse à toujours apprendre et à relever de nouveaux défis. N'hésitez pas à me contacter pour discuter de projets passionnants."
        }
      />
    </section>
  );
}

export default Profil;
