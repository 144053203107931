import Contact from "./Contact";

function Footer() {
  return (
    <section id="footer" className=" bg-slate-950 text-white">
      <Contact />
      <p></p>
    </section>
  );
}

export default Footer;
