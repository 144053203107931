import React from "react";
import CurrentSituation from "./components/CurrentSituation.jsx";
import Home from "./components/01-Home.jsx";
import Nav from "./components/00-Nav.jsx";
import Profil from "./components/02-Profil.jsx";
import Portfolio from "./components/05-Portfolio.jsx";
import Skills from "./components/03-Skills.jsx";
import Experience from "./components/04-Experience.jsx";
import Footer from "./components/06-Footer.jsx";
import "./App.css";
import "./assets/styles/tailwind.css";
import "./assets/styles/responsive.css";  

function App() {
  return (
    <>
      <Nav />
      <Home />
      <CurrentSituation />
      <Profil />
      <Skills />
      <Experience />
      <Portfolio />
      <Footer />
    </>
  );
}

export default App;
