function Skills() {
  function SkillsCards({ valueTitle, valueList, classSpec }) {
    return (
      <li
        className={
          classSpec +
          " w-40 bg-slate-900 border rounded-3xl m-2 text-center font-semibold"
        }
      >
        <h3>{valueTitle}</h3>
        <ul>{valueList}</ul>
      </li>
    );
  }

  return (
    <section
      id="skill"
      className="flex flex-col justify-between items-center h-screen bg-slate-950 text-white"
    >
      <div>
        <h2 className="text-4xl text-purple-500 p-5">Compétences</h2>
        <p className="mx-5 text-center">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto
          impedit laudantium quam voluptas, accusantium molestiae?
        </p>
      </div>
      <div className="grid grid-cols-2 gap-2 m-5">
        <SkillsCards classSpec="row-span-2" valueTitle={"Front-end"} />
        <SkillsCards classSpec="h-28" valueTitle={"Back-end"} />
        <SkillsCards classSpec="h-28" valueTitle={"CMS"} />
      </div>
      <div></div>
    </section>
  );
}

export default Skills;
