function Portfolio() {
  function Cards({ classSpec, valueImgSrc, valueTitle, valueDesc, lien}) {
    return (
      <li
        className={
          classSpec +
          "flex flex-col p-3 bg-slate-900 min-h-20 min-w-20 border rounded-3xl mx-2"
        }
      >
        <img className="h-28 w-48 rounded-xl border" src={valueImgSrc} alt="" />
        <h4 className="text-xl font-medium text-purple-200">{valueTitle == "" || valueTitle == null ? "Titre" : valueTitle}</h4>
        <p className="text-gray-400">
          {valueDesc == "" || valueDesc == null ? "Description.." : valueDesc}
        </p>
        <a className="ml-2   text-xs text-white hover:text-blue-400 transition delay-75 " href={lien}>{lien}</a>
      </li>
    );
  }

  return (
    <section
      id="portfolio"
      className=" flex flex-col justify-between items-center h-screen bg-slate-950 text-white"
    >
      <h2 className="text-purple-500 text-3xl">Portfolio</h2>
      <ul className="flex flex-row overflow-x-auto">
        <Cards valueTitle={"portal.maelcorp"} lien={"portal.maelcorp.com"}/>
        <Cards />
      </ul>
      <div></div>
    </section>
  );
}

export default Portfolio;
