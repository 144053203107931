function Home() {
  return (
    <section
      id="home"
      className=" h-screen flex flex-col justify-center items-center bg-slate-950 text-white"
    >
      <h1 className="uppercase text-4xl">Web Developer</h1>
      <h2 className="text-4xl mb-5 text-purple-500">Ilann Barreteau</h2>
      <ul className="flex items-center flex-col">
        <li className="text-1xl">
          <a href="#ilann.barreteau.pro@gmail.com">
            ilann.barreteau.pro@gmail.com
          </a>
        </li>
        <li className="text-1xl">
          <a href="#+33682838456">+33 6 82 83 84 56</a>
        </li>
      </ul>
      <a href="#"></a>
    </section>
  );
}

export default Home;
